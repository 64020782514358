const pages = [
	"Main",
	{
		type: "page",
		name: "Dashboard",
		icon: "fa fa-home",
		page: "/dashboard"
	},
	{
		type: "dropdown",
		name: "Listing",
		icon: "fa fa-list",
		menu: [
			// "Main",
			{
				type: "page",
				name: "Listing Search",
				icon: "fa fa-search",
				page: "/listing",
				permissions: [
					"LISTING-SEARCH"
				]
			},
			{
				type: "page",
				name: "Listing Import",
				icon: "fa fa-cloud-upload",
				page: "/listing/import",
				permissions: [
					"LISTING-IMPORT"
				]
			},
			// "Retire Listing",
			// {
			// 	type: "page",
			// 	name: "Retire Listing",
			// 	icon: "fa fa-file-archive-o",
			// 	page: "/listing/remove",
			// 	permissions: [
			// 		"LISTING-RETIRE"
			// 	]
			// },
			// {
			// 	type: "page",
			// 	name: "Retire Listing History",
			// 	icon: "fa fa-history",
			// 	page: "/listing/remove-history",
			// 	permissions: [
			// 		"LISTING-RETIRE"
			// 	]
			// },
			// "Reference",
			// {
			// 	type: "page",
			// 	name: "Listing Date Details",
			// 	icon: "fa fa-files-o",
			// 	page: "/listing/listing-date-details",
			// 	permissions: [
			// 		"LISTING-REFERENCE"
			// 	]
			// },
		],
		sub_pages: [
			// {
			// 	type: "page",
			// 	name: "Listing Detail",
			// 	page: "/listing/:id"
			// }
		]
	},
	{
		type: "dropdown",
		name: "Listing Content",
		icon: "fa fa-file-text",
		menu: [
			{
				type: "page",
				name: "Content Queue Detail",
				icon: "fa fa-columns",
				page: "/listing/updates",
				permissions: [
					"LISTING-CONTENT-UPDATE"
				]
			},
			{
				type: "page",
				name: "Content Queue Summary",
				icon: "fa fa-columns",
				page: "/listing/update-summary",
				permissions: [
					"LISTING-CONTENT-UPDATE"
				]
			},
			{
				type: "page",
				name: "Content Emergency Update",
				icon: "fa fa-bell",
				page: "/listing/update-request",
				permissions: [
					"LISTING-CONTENT-UPDATE"
				]
			},
			{
				type: "page",
				name: "Image Delete Queue",
				icon: "fa fa-bell",
				page: "/listing/image-queue",
				//permissions: []
			},
			{
				type: "page",
				name: "Listing Api History",
				icon: "fa fa-history",
				page: "/listing/api-history",
				//permissions: []
			},
			{
				type: "page",
				name: "Product Content Block List",
				icon: "fa fa-columns",
				page: "/listing/content-block-list",
				//permissions: []
			}
		]
	},
	{
		type: "dropdown",
		name: "Listing Price",
		icon: "icon-coin-dollar",
		menu: [
			{
				type: "page",
				name: "Price Advisor",
				icon: "fa fa-database",
				page: "/price/advisor",
				// Either one permission can enter this page
				permissions: [
					"LISTING-PRICE-UPDATE",
					"LISTING-PRICE-ADVISOR-READ"
				]
			},
			{
				type: "page",
				name: "Listing Contribution Margin (Beta 1.0)",
				icon: "fa fa-database",
				page: "/price/listing-contribution-margin",
				// Either one permission can enter this page
				// permissions: [
				// 	"LISTING-PRICE-UPDATE",
				// 	"LISTING-PRICE-ADVISOR-READ"
				// ]
			},
			{
				type: "page",
				name: "Export Price Estimation",
				icon: "fa fa-calculator",
				page: "/price/estimation-export",
				permissions: [
					"LISTING-PRICE-EST"
				]
			},
			// {
			// 	type: "page",
			// 	name: "Map Price",
			// 	icon: "fa fa-check-square-o",
			// 	page: "/listing/price/map-price",
			// 	permissions: [
			// 		"LISTING-PRICE-UPDATE"
			// 	]
			// },
			{
				type: "page",
				name: "Update Listing Price",
				icon: "fa fa-cloud-upload",
				page: "/listing/price/update",
				permissions: [
					"LISTING-PRICE-UPDATE"
				]
			}
		]
	},
	{
		type: "dropdown",
		icon: "fa fa-cube",
		name: "Listing Inventory",
		menu: [
			{
				type: "page",
				name: "Custom Inventory",
				icon: "fa fa-rotate-right",
				page: "/inventory/reserve",
				permissions: [
					"LISTING-INVENTORY"
				]
			},
			{
				type: "page",
				name: "Inventory Block Listing",
				icon: "fa fa-shield",
				page: "/inventory/block.listing",
				permissions: [
					"BLOCK-LISTING"
				]
			},
			{
				type: "page",
				name: "Inventory Allocation",
				icon: "fa fa-rotate-right",
				page: "/inventory/allocation",
				permissions: [
					"INVENTORY-ALLOCATION"
				]
			},
			{
				type: "page",
				name: "Inventory Report",
				icon: "fa fa-flag",
				page: "/inventory/report",
				permissions: [
					"LISTING-INVENTORY"
				]
			},
		],
		sub_pages: [
			{
				type: "page",
				name: "Reserve Detail",
				page: "/inventory/reserve/:id",
				permissions: [
					"LISTING-INVENTORY"
				]
			},
			{
				type: "page",
				name: "Listing Inventory",
				page: "/inventory/shein-ss/:id"
			}
		]
	},
	{
		type: "dropdown",
		name: "Listing Review",
		icon: "fa fa-commenting-o",
		menu: [
			{
				type: "page",
				name: "Review Search",
				icon: "fa fa-search",
				page: "/review/search",
				permissions: [
					"LISTING-REVIEW"
				]
			},
			{
				type: "page",
				name: "Reviews By Product",
				icon: "fa fa-filter",
				page: "/review/classification",
				permissions: [
					"LISTING-REVIEW"
				]
			},
			{
				type: "page",
				name: "Reviews Report",
				icon: "fa fa-file-excel-o",
				page: "/review/report",
				permissions: [
					"LISTING-REVIEW"
				]
			}
		]
	},
	{
		type: "dropdown",
		name: "Channel Operation",
		icon: "fa fa-send",
		menu: [
			"Channel Report",
			{
				type: "page",
				name: "Business Report",
				icon: "fa fa-file-pdf-o",
				page: "/business-report",
				permissions: [
					"LISTING-REFERENCE"
				]
			},
			"Video Template",
			{
				type: "page",
				name: "Video Template Download",
				icon: "fa fa-cloud-download",
				page: "/video/template",
				permissions: [
					"LISTING-TEMPLATE"
				]
			},
			"Listing Template",
			// removed at 23.9.12
			// {
			// 	type: "page",
			// 	name: "Assign Listing Category",
			// 	icon: "fa fa-gear",
			// 	page: "/listing/pre-launch/pending",
			// 	permissions: [
			// 		"LISTING-TEMPLATE"
			// 	]
			// },
			{
				type: "page",
				name: "Listing Template Download",
				icon: "fa fa-cloud-download",
				page: "/channel-advisor/download",
				permissions: [
					"LISTING-TEMPLATE"
				]
			},
			{
				type: "page",
				name: "Listing Template Upload",
				icon: "fa fa-cloud-upload",
				page: "/channel-advisor/upload",
				permissions: [
					"LISTING-TEMPLATE"
				]
			},
			"Walmart",
			{
				type: "page",
				name: "Dimension Issue Report",
				icon: "fa fa-tachometer",
				page: "/walmart/dimension-issue"
			},
			"Amazon",
			{
				type: "page",
				name: "Export Listing File",
				page: "/amazon/export-listing",
				icon: "fa fa-cloud-download",
				permissions: [
					"LISTING-CH-AMAZON"
				]
			}
			// {
			// 	type: "page",
			// 	name: "Listing Update Report",
			// 	page: "/amazon/listing-update-report",
			// 	icon: "fa fa-tachometer",
			// 	permissions: [
			// 		"LISTING-CH-AMAZON"
			// 	]
			// }
		],
		sub_pages: [
			{
				type: "page",
				name: "Upload Status",
				page: "/channel-advisor/upload/:id"
			}
		]
	},
	{
		type: "dropdown",
		icon: "fa fa-gears",
		name: "Setting",
		menu: [
			// {
			// 	type: "page",
			// 	name: "Expected Margin",
			// 	icon: "fa fa-gear",
			// 	page: "/setting/expected-margin",
			// 	permissions: [
			// 		"LISTING-CONFIG"
			// 	]
			// },
			{
				type: "page",
				name: "LMS Permission",
				icon: "fa fa-universal-access",
				page: "/setting/permission",
				permissions: [
					"LMS-ADMIN"
				]
			}
		]
	}
];

module.exports = pages.reduce((prev, curr) => {
	if (typeof curr === "string") { return prev; }
	if (Array.isArray(curr)) { return [].concat(prev, curr); }
	prev.push(curr);
	return prev;
}, []);

module.exports.sidebar = pages;
