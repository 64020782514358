import moment from "moment";
import Router from "next/router";

moment.prototype.standard = function standard() {
	return this.format("Do MMMM, YYYY hh:mm a");
};

moment.prototype.shortStandard = function shortStandard() {
	return this.format("Do MMM, hh:mm a");
};

// https://github.com/zeit/next-plugins/issues/282
Router.events.on(
	"routeChangeComplete",
	(url) => {
		if (window && window._paq) {
			window._paq.push(["setCustomUrl", url]);
			window._paq.push(["setDocumentTitle", document.title]);
			window._paq.push(["trackPageView"]);
		}
		// $("#main").animate({ scrollTop: 0 }, 200);
	}
);
