import React from "react";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import { DefaultSeo } from "next-seo";
import { Provider } from "react-redux";
import withRedux from "next-redux-wrapper";
import NextApp from "next/app";
import { ToastContainer } from "react-toastify";
import Main from "@/ReUtil/components/Main";
import store from "../store";
import webConfig from "../web.config";
import "react-day-picker/lib/style.css";
import "@/ReUtil/styles/index.scss";
import "react-tabs/style/react-tabs.css";
import "../styles/main.scss";
import "@/ReUtil/index";
import "@/ReUtil/utils/alert/Toastify.scss";
import "@/ReUtil/utils/alert/Toastify.Lihkg.scss";

@withRedux(store)
class App extends NextApp {

	render() {
		const { Component, pageProps, store } = this.props;
		return (
			<>
				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
					<script src="/__ENV.js" />
				</Head>
				<NextNProgress color="#ff9532" />
				<DefaultSeo
					title="Home"
					titleTemplate="LMS - %s"
					description={webConfig.description}
					canonical={webConfig.canonical}
					additionalMetaTags={[{
						name: "keywords",
						content: webConfig.keywords
					}]}
				/>
				<ToastContainer
					autoClose={3000}
					hideProgressBar={false}
				/>
				<Provider store={store}>
					<Main {...pageProps}>
						<Component {...pageProps} />
					</Main>
				</Provider>
			</>
		);
	}

}

export default App;
